import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import ElementUI from "element-ui"
import '@/theme/index.scss'
import "@/theme/settingColor.css"
import "@/theme/scroll.css"

Vue.use(ElementUI)

new Vue({
  render: h => h(App),
}).$mount('#app')
