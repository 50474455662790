<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pages {
  height: 100%;
}
</style>


<template>
  <div class="pages">
    <altimeter ref="Altimeter"  />

  </div>
</template>

<script>
import Altimeter from "./Altimeter"

export default {
  name: 'Camrea',
  components: { Altimeter },
}
</script>

