<template>
    <div class="flexBoxSon">
        <div class="posr" v-for="(item,index) in list" :key="index">
            <div class="gx">
                <el-checkbox v-model="item.isCheck" @change="Select">1</el-checkbox>
            </div>
            <el-image
                style="width: 100px; height: 100px"
                :src="item.url"
                :preview-src-list="[item.url]"
            ></el-image>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'imgBox',
    data(){
      return{
        list:[]
      }
    },
    methods:{
      show(list){
        this.list = list
      },
      Select(){
        console.log(this.list)
        this.$emit("returnBack",this.list)
      }
    }

  }
</script>

<style scoped>
    .flexBoxSon{
        display: flex;
        flex-wrap: wrap;
    }
    .posr{
        position: relative;
        margin: 0 10px 10px 0;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }
    .gx{
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        z-index: 2;
    }
</style>
