<style scoped lang="scss">
.high-page {
  width: 100%;
  height: 100%;
  &-box {
    width: 100%;
    height: 100%;
    display: flex;
    &-camera {
      height: 100%;
      flex: 1;
      position: relative;
      .show-area {
        height: 100%;
      }
      .control-button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0,0,0,.1);
        padding: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
    &-photo {
      width: 330px;
      padding: 10px;
      .photo-label {
        padding-bottom: 10px;
      }
    }
  }
}
</style>
<template>
    <div class="high-page">
      <div class="high-page-box" v-if="cameraStatus"  v-loading="loading">
        <div class="high-page-box-camera">
          <div class="show-area">
            <el-scrollbar style="height: 100%">
                <img v-if="show" ref="cameraCanvas" width="100%" height="100%"  :src="`${base_url}/video=stream&camidx=0`" alt="主摄像头">
            </el-scrollbar>
          </div>
          <div class="control-button">
            <div class="button-left">
              <el-button size="small" type="primary" icon="el-icon-refresh" @click="init()">刷新</el-button>
              <el-button size="small" type="primary" icon="el-icon-minus" @click="ZoomOut(0)">缩小</el-button>
              <el-button size="small" type="primary" icon="el-icon-plus" @click="ZoomOut(1)">放大</el-button>
              <el-button size="small" type="primary" icon="el-icon-refresh-right" @click="rotate">旋转</el-button>
              <el-button size="small" type="primary" icon="el-icon-minus" @click="ZoomOut(0)">原图</el-button>

            </div>
            <div class="button-right">
              <el-button size="small" type="primary" icon="el-icon-camera-solid" @click="photograph">拍照</el-button>
            </div>
          </div>

        </div>
        <div class="high-page-box-photo">
          <div class="photo-label">已拍摄图片:</div>
          <el-scrollbar style="height: 100%">
            <imgBox ref="imgBox" @returnBack="returnBack"></imgBox>
          </el-scrollbar>
        </div>
      </div>

        <div style="height: 80vh;display: flex;justify-content: center;align-items: center;flex-direction: column" v-else>
            <i class="el-icon-warning-outline" style="font-size: 108px;margin-bottom: 30px"></i>
            <div>
                高拍仪连接失败，请检测是否是以下原因：
                <p>
                    1.是否安装了驱动，如未安装
                    <a class="down" href="https://altimeter.nyzf.info/sdk/WebCamera_EL_D_V1.2.3.exe" target="_blank">点击下载</a>
                    后安装
                </p>
                <p>
                    2.高拍仪是否正确链接
                </p>
            </div>
        </div>
    </div>
</template>

<script>

  import imgBox from './imgBox'
  import axios from 'axios'

  export default {
    name: 'index',
    components: {
      imgBox
    },
    data () {
      return {
        // base_url: 'http://192.168.50.243:38088',
        base_url: 'http://127.0.0.1:38088',
        width:"600",
        height:"500",
        fileUrl: [],
        fileList: [],
        show: true,
        cameraStatus: false,
        crop: 0,
        loading: false,
        config: {
          withCredentials: false
        }
      }
    },
    created () {
      this.init()

    },
    mounted () {
      this.getMessageByParent()
    },
    methods: {
      // 获取父级消息
      getMessageByParent() {
          window.addEventListener('message', this.handleMessage)
      },
      // 告诉父组件准备好接收消息了
      postParentMessage(cmd, params) {
        window.parent.postMessage({ cmd, params }, '*')
      },


      handleMessage (event) {
        // 根据上面制定的结构来解析 iframe 内部发回来的数据
        const data = event.data
        console.log('handleMessage=====son', data)
        switch (data.cmd) {
          case 'init':
            // 业务逻辑
            if (data.params) this.init()
            break
        }
      },

      // 更新摄像头状态
      updateCameraStatus(bool) {
        // this.$emit("cameraStatus",bool)
        this.loading = false
        this.postParentMessage('cameraStatus', bool)

      },

      // 初始化摄像头
      init () {
        console.log('init')
        this.cameraStatus = true
        this.loading = true
        this.show = false
        // 判断设备是否连接
        axios.post(this.base_url + "/device=isconnect", null, this.config).then((res)=>{
          if (res.data && res.data.data && res.data.data > 0) {
            this.show = true
            this.$nextTick(() => {
              this.imgLoad()

            })
            // setTimeout(() => {
            //   this.updateCameraStatus(true)
            // }, 2000)

          } else {
            this.cameraStatus = false
            this.updateCameraStatus(false)

          }
        }).catch(() => {
          this.cameraStatus = false
          this.updateCameraStatus(false)
        })

      },
      imgLoad() {
        const imgRef = this.$refs.cameraCanvas
        let _this = this;
        let timer = setInterval(function() {
          console.log('imgRef', imgRef)
          console.log('imgRef.complete', imgRef.complete)
          if (imgRef.complete) {
            //渲染成功则
            clearInterval(timer);
            _this.updateCameraStatus(true)
          }
        }, 50);
      },

      // 放大缩小
      ZoomOut (type) {
        if (type === 0) {
          this.crop -= 1
          if (this.crop < 0) {
            this.crop = 0
            this.$message.warning('已缩放至最小')
            return
          }
        } else if (type === 1) {
          this.crop += 1
          if (this.crop > 9) {
            this.crop = 9
            this.$message.warning('已放大至最大')
            return
          }
        }


        let params;
        //corp：0-9，0为原图
        params = "{\"camidx\":\"" + "0" + "\",\"corp\":\"" + this.crop +"\"}";


        axios.post(this.base_url + "/stream=zoominout", params, this.config).then((res)=>{
          console.log(res)

        }).catch(() => {

        })

      },
      // 旋转
      rotate () {
        let data = {"camidx": '0', "rotate": String(270)}
        axios.post(this.base_url + "/video=rotate", JSON.stringify(data), this.config).then((res)=>{
          console.log('旋转成功！')
        })
      },
      // 拍照
      photograph () {//自定义拍照事件
        let data = {
          "filepath": "base64",
          "rotate": "0",
          "cutpage": "0",
          "camidx": "0",
          "ColorMode": "0",
          "quality": "3"
        }
        axios.post(this.base_url + "/video=grabimage", JSON.stringify(data), this.config).then((res)=>{
          console.log('主头拍照成功', res.data)
          let base64Url = "data:image/jpg;base64," + res.data.photoBase64
          let timestamp = new Date().getTime()
          let str = this.dataURLtoFile(base64Url,timestamp+".jpg")
          this.fileUrl.push({ url: base64Url, file: str, isCheck:true, fileType: 'jpg', fileExplain: timestamp })
          this.$refs.imgBox.show(this.fileUrl)
          this.returnBack(this.fileUrl)
        })

      },
      //将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
      dataURLtoFile (dataurl, name) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], name, {
          type: mime,
        })
      },
      // 返回给上级
      returnBack(e){//返回勾选的图片
        this.fileUrl = e
        let arrImg = []
        for (let i = 0; i < e.length; i++) {
          if (e[i].isCheck) arrImg.push(e[i])
        }

        this.postParentMessage('selectImgReturn', arrImg)

        // this.$emit("selectImgReturn",arrImg)
      }
    },
    destroyed () {
      // 注意移除监听！注意移除监听！注意移除监听！
      window.removeEventListener('message', this.handleMessage)
    }
  }
</script>


